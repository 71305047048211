.aboutContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 78%;
}

span {
  font-family: Montserrat-SemiBold !important;
}

.descriptionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.guaranteeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 30px;
}

.guaranteeWrapper text {
  padding: 15px 0;
}

.guaranteeWrapper h1 {
  font-family: Lovelo Black;
  font-size: 20px;
}

.guaranteeInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12pt;
  font-family: Montserrat-Light;
}

.infoLogo {
  width: 70px;
  height: 70px;
  padding: 0px 10px;
}

.guaranteeLogo {
  width: 167px;
  padding: 10px;
}

.heroIntro {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 10pt;
  font-family: Montserrat-Light;
  padding-top: 15px;
}

.heroIntro text {
  padding: 15px 0;
}

.imageWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}

.buttonWrapper {
  margin: 30px;
}

.darkGhostBtn {
  border: none;
  padding: 15px 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 60px;
  opacity: 1;
  color: #000000;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  font-family: Lovelo Black;
}

.darkGhostBtn:hover {
  background: #000000;
  color: white;
}

@media (min-width: 700px) {
  .greyLogo {
    width: 200px;
  }
  .infoLogo {
    width: 100px;
    height: 100px;
    padding: 0px 20px;
  }
  .guaranteeLogo {
    width: 200px;
  }
  .buttonWrapper {
    margin: 35px;
  }
  .guaranteeWrapper {
    margin-bottom: 40px;
  }
  .guaranteeWrapper h1 {
    font-size: 25px;
  }
}

@media (min-width: 1024px) {
  .descriptionWrapper {
    flex-direction: row;
    padding: 75px 0;
  }
  .guaranteeInfoWrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 20px;
  }
  .guaranteeWrapper {
    margin-bottom: 50px;
  }
  .guaranteeWrapper h1 {
    font-size: 30px;
  }
  .guaranteeLogo {
    width: 300px;
    padding: 0 0 0 20px;
  }
  .heroIntro {
    text-align: left;
    font-size: 18px;
    padding-left: 100px;
  }
  .greyLogo {
    width: 280px;
  }
  .infoLogo {
    width: 150px;
    height: 150px;
    padding: 0px 30px;
  }
  .buttonWrapper {
    margin: 45px;
  }
  .darkGhostBtn {
    font-size: 18px;
    padding: 20px 78px;
  }
}

@media (min-width: 1024px) and (orientation: portrait) {
  .heroIntro {
    text-align: left;
    font-size: 16px;
    padding-left: 100px;
  }
}

@media (min-width: 1441px) {
  .heroIntro {
    font-size: 25px;
    padding-left: 150px;
  }
  .greyLogo {
    width: 325px;
  }
  .infoLogo {
    width: 187px;
    height: 187px;
    padding: 0px 40px;
  }
  .guaranteeLogo {
    width: 345px;
    padding: 0 0 0 50px;
  }
  .buttonWrapper {
    margin: 50px;
  }
  .guaranteeInfoWrapper {
    font-size: 25px;
  }
  .guaranteeWrapper {
    margin-bottom: 80px;
  }
  .guaranteeWrapper h1 {
    font-size: 40px;
  }
}
