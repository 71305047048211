.navbar {
  display: flex;
  padding: 0 2em;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  height: 80px;
  text-transform: uppercase;
  font-family: Lovelo Black;
  font-size: 20px;
  width: 100%;
  z-index: 2;
  border-bottom: 1px solid #707070;
  opacity: 1;
}
