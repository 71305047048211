.navContainer {
  display: flex;
  justify-content: center;
}

.navContainer ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 1px;
  list-style: none;
}

.navLink {
  color: #000000;
  text-align: left;
  text-decoration: none;
  margin-right: 50px;
}

@media (max-width: 1024px) {
  .navContainer {
    display: none;
  }
}
