body {
  margin: 0;
  height: 100vh;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Lovelo Black";
  src: local("Lovelo Black"), url(../fonts/Lovelo_Black.otf) format("truetype");
}

@font-face {
  font-family: "Raleway-Light";
  src: local("Raleway-Light"),
    url(../fonts/Raleway-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway-SemiBold";
  src: local("Raleway-SemiBold"),
    url(../fonts/Raleway-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway-Bold";
  src: local("Raleway-Bold"), url(../fonts/Raleway-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-Light";
  src: local("OpenSans-Light"),
    url(../fonts/OpenSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-SemiBold";
  src: local("OpenSans-SemiBold"),
    url(../fonts/OpenSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-Bold";
  src: local("OpenSans-Bold"),
    url(../fonts/OpenSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url(../fonts/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat-Light"),
    url(../fonts/Montserrat-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
    url(../fonts/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url(../fonts/Montserrat-Bold.ttf) format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
