.googleMapContainer {
  display: flex;
  width: 100%;
}

.googleMapWrapper {
  display: flex;
  width: 100%;
  height: 45vh;
}

.mapColor {
  border: 0;
  filter: saturate(0%);
  margin-bottom: -7px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.mapColor:hover {
  filter: saturate(100%);
}

@media (min-width: 1024px) {
  .googleMapWrapper {
    height: 50vh;
  }
}
