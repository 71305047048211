.notFoundContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 83vh;
}

.notFoundWrapper {
  width: 90%;
  text-align: center;
  line-height: 1.5rem;
  font-family: Lovelo Black;
}

.notFoundWrapper h1 {
  margin: 50px;
}
