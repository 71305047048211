.projectDetailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.projectDetailsImageWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 90%;
  max-width: 1275px;
}

.projectDetailsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 100px auto 10px;
  width: 90%;
  max-width: 1900px;
}

.detailsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 30px 0;
}

.detailsWrapper h1 {
  text-transform: uppercase;
  font-family: Lovelo Black;
  font-size: 30px;
  margin: 10px auto;
}

.detailsWrapper p {
  font-family: OpenSans-Light;
  font-size: 20px;
}

.projectDetailsImage {
  width: 301px;
  height: 230px;
  max-height: 340px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .projectDetailsImage {
    width: 400px;
    height: 300px;
  }
}

@media (min-width: 1024px) {
  .projectDetailsWrapper {
    flex-direction: row;
  }
  .projectDetailsImageWrapper {
    width: 85%;
  }
  .projectDetailsImage {
    margin: 0px 15px;
    width: 450px;
    height: 100vh;
  }
  .detailsWrapper {
    margin: 20px;
    align-items: flex-start;
  }
  .detailsWrapper h1 {
    margin-left: 0;
    font-size: 60px;
  }
  .detailsWrapper p {
    margin-left: 0;
  }
}

@media (min-width: 1400px) {
  .projectDetailsWrapper {
    margin: 150px auto 10px;
  }
  .projectDetailsImage {
    width: 45%;
    height: 100vh;
    max-height: 380px;
  }
  .detailsWrapper h1 {
    font-size: 70px;
  }
  .detailsWrapper p {
    font-size: 22px;
  }
}
