.projectImageWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.projectCard {
  margin: 10px auto;
}

.projectImage {
  width: 301px;
  height: 230px;
}

@media (min-width: 768px) {
  .projectImage {
    width: 400px;
    height: 300px;
  }
}
