.contactUsContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #3c3c3c;
  height: 180px;
  padding: 28px;
}

p {
  margin: 0;
}

.buttonWrapper {
  margin: 30px 0px;
}

.infoWrapper {
  color: white;
  font-family: Lovelo Black;
  font-size: 10px;
  margin: 15px;
}

.listenWrapper {
  color: white;
  font-family: Montserrat-SemiBold;
  font-size: 20px;
}

.transparentBtn {
  padding: 15px 55px;
  background: #3c3c3c 0% 0% no-repeat padding-box;
  border: 1px solid white;
  border-radius: 60px;
  opacity: 1;
  color: white;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  font-family: Lovelo Black;
}

.transparentBtn:hover {
  background: white;
  color: black;
  border: 1px solid black;
}

@media (min-width: 768px) {
  .contactUsContainer {
    padding: 55px;
  }
  .infoWrapper {
    font-size: 12px;
  }
  .listenWrapper {
    font-size: 25px;
  }
  .transparentBtn {
    padding: 20px 60px;
  }
}

@media (min-width: 1024px) {
  .contactUsContainer {
    padding: 55px;
  }
  .listenWrapper {
    font-size: 28px;
  }
  .transparentBtn {
    padding: 20px 63px;
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .contactUsContainer {
    padding: 67px;
  }
  .infoWrapper {
    font-size: 15px;
  }
  .listenWrapper {
    font-size: 30px;
  }
  .transparentBtn {
    padding: 20px 68px;
    font-size: 20px;
  }
}
