.projectsBannerContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.projectsBannerWrapper {
  margin: 0px auto 20px;
}

.houseWrapper {
  height: 155px;
}

@media (min-width: 768px) {
  .houseWrapper {
    width: 90% !important;
    height: 180px;
  }
  .projectsBannerWrapper {
    width: 90%;
  }
  .InfiniteCarouselFrame {
    width: 90% !important;
    margin: 0 auto;
  }
}

@media (min-width: 1024px) {
  .houseWrapper {
    width: 85%;
    height: 215px;
  }
}

@media (min-width: 1400px) {
  .houseWrapper {
    height: 250px;
  }
}
