.contactContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.contactWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.locationWrapper {
  display: flex;
  padding-bottom: 100px;
  justify-content: center;
  flex-direction: column;
}

.locationWrapper h1 {
  font-family: Lovelo Black;
  font-size: 18px;
}

.locationWrapper p {
  font-family: OpenSans-Light;
  font-size: 14px;
}

.termsLink {
  text-decoration: none;
  color: black;
  font-family: OpenSans-Bold;
}

.formContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  padding: 5em 0 0;
}

.upContainer {
  display: flex;
  flex-direction: column;
  width: 80vw;
}

.downContainer {
  display: flex;
  flex-direction: column;
  width: 80vw;
}

.inputField {
  font-family: OpenSans-Light !important;
  padding: 20px 0 !important;
}

.inputField label {
  font-family: OpenSans-Light !important;
}

.largeInputField {
  padding: 20px 0 !important;
  width: 100%;
}

.largeInputField label {
  font-family: OpenSans-Light !important;
}

.cinderBlockWrapper {
  position: absolute;
  top: 20%;
  right: -200px;
}

.cinderBlock {
  height: 350px;
}

.checkBoxField {
  display: flex;
  font-size: 14px;
  font-family: OpenSans-Light;
  margin-top: 30px;
}

.checkBoxField p {
  margin: auto 0;
}

.buttonField {
  margin: 50px auto;
}

.darkBtn {
  border: none;
  padding: 12px 60px;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  color: white;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  font-family: Lovelo Black;
}

.darkBtn:hover {
  background: #292929 0% 0% no-repeat padding-box;
}

@media (min-width: 1024px) {
  .formContainer {
    width: 50vw;
  }
  .upContainer {
    flex-direction: row;
    width: 100%;
  }
  .downContainer {
    flex-direction: row;
    width: 100%;
  }
  .inputField {
    padding-right: 100px !important;
  }
  .largeInputField {
    padding-right: 100px !important;
  }
  .checkBoxField {
    font-size: 18px;
    margin-top: 40px;
  }
  .darkBtn {
    font-size: 18px;
  }
}

@media (max-width: 1023px) {
  .locationWrapper {
    display: none;
  }
  .cinderBlockWrapper {
    display: none;
  }
}
