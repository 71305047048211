.termsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 50px 0px;
}

.termsWrapper {
  width: 90%;
  text-align: center;
  line-height: 1.5rem;
}

.termsWrapper h2 {
  text-align: center;
  margin: 50px;
}

.termsWrapper a {
  color: black;
  text-decoration: none;
  font-family: OpenSans-Bold;
}

@media (min-width: 768px) {
  .termsWrapper {
    text-align: left;
  }
}
