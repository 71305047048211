.globalProjectsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px;
  width: 90%;
  max-width: 1275px;
}

.projectsChoice {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-family: Lovelo Black;
  font-size: 12px;
  width: 100%;
}

.projectsLink {
  color: #000000;
  text-decoration: none;
  margin: 5px;
}

@media (min-width: 768px) {
  .projectsChoice {
    font-size: 15px;
    margin: 10px 0;
  }
  .projectsLink {
    margin: 10px;
  }
}

@media (min-width: 1024px) {
  .projectsChoice {
    font-size: 18px;
    margin: 20px 0;
  }
}

@media (min-width: 1024px) {
  .projectsChoice {
    font-size: 20px;
  }
}

.projectImageWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.projectCard {
  margin: 10px auto;
}

.projectImage {
  width: 301px;
  height: 200px;
}

@media (min-width: 768px) {
  .projectImage {
    width: 400px;
    height: 250px;
  }
}
