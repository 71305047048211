.footer {
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  height: 180px;
  font-family: OpenSans-Light;
  font-size: 12px;
  width: 100%;
  background-color: #1e1e1e;
  opacity: 1;
}

.footerWrapper {
  display: flex;
  align-items: center;
}

.logoWrapper {
  margin-left: 10px;
}

.footerContactWrapper {
  display: flex;
  padding-left: 30px;
  justify-content: center;
  flex-direction: column;
  color: white;
}

@media (min-width: 768px) {
  .footerContactWrapper {
    padding-left: 50px;
  }
  .footer {
    font-size: 14px;
  }
}
