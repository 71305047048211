.headerContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  background-color: #3c3c3c;
  padding: 110px 0px 65px;
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 85%;
}

.headerContainer h1 {
  padding: 0;
  color: white;
  font-family: Lovelo Black;
  font-size: 30px;
}

.headerContainer p {
  color: white;
  font-family: OpenSans-Light;
  font-size: 20px;
}

.headerContainer span {
  color: #f2ce3c;
  font-family: OpenSans-SemiBold;
  font-size: 20px;
}

@media (min-width: 768px) {
  .headerContainer h1 {
    font-size: 60px;
  }
  .headerContainer p {
    font-size: 25px;
  }
  .headerContainer span {
    font-size: 25px;
  }
}

@media (min-width: 1024px) {
  .headerContainer h1 {
    font-size: 90px;
  }
  .headerContainer p {
    font-size: 30px;
  }
  .headerContainer span {
    font-size: 30px;
  }
}
