.burgerMenu {
  position: absolute;
  top: 79px;
  width: 100%;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
}

.item {
  width: 100%;
  opacity: 0;
  animation: 0.5s appear forwards;
}

.line {
  width: 90%;
  animation: 0.5s shrink forwards;
  margin: 5px auto;
  height: 1px;
  background: black;
}

.navLink {
  font-family: Lovelo Black;
  font-size: 20px;
  color: #000000;
  text-align: left;
  text-decoration: none;
}

.buttonStyle {
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  padding: 2rem 0;
  margin: 0 5%;
  animation: 0.5s slideIn forwards;
}

@media (min-width: 1025px) {
  .burgerContainer {
    display: none;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(80%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}
