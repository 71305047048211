.partnersBannerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0px 0 30px;
}

.partnersImage {
  padding: 15px;
  width: 10%;
}

@media (max-width: 767px) {
  .partnersBannerContainer {
    display: none;
  }
}

@media (min-width: 1200px) {
  .partnersImage {
    width: 12%;
  }
}

@media (min-width: 1500px) {
  .partnersImage {
    width: 15%;
  }
}
