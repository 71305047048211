.servicesInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  width: 75%;
}

.servicesInfoIntro h1 {
  font-family: Lovelo Black;
  font-size: 20px;
  text-transform: uppercase;
}

.servicesInfoIntro p {
  font-family: Montserrat-Regular;
  font-size: 15px;
}

.servicesInfoIntro span {
  font-family: Montserrat-SemiBold;
  font-size: 15px;
}

.servicesInfoResume {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  max-width: 1200px;
  width: 95%;
}

.servicesInfoResume h2 {
  font-family: Lovelo Black;
  font-size: 15px;
  text-transform: uppercase;
}

.servicesInfoResume p {
  font-family: Montserrat-Regular;
  font-size: 12px;
}

.servicesInfoResume span {
  font-family: Montserrat-SemiBold;
  font-size: 12px;
}

.servicesImage {
  margin: 15px 0;
  width: 277px;
}

@media (min-width: 768px) {
  .servicesInfoContainer {
    width: 85%;
  }
  .servicesInfoIntro h1 {
    font-size: 25px;
  }
  .servicesInfoIntro p {
    font-size: 20px;
  }
  .servicesInfoIntro span {
    font-size: 20px;
  }
  .servicesInfoResume h2 {
    font-size: 16px;
  }
  .servicesInfoResume p {
    font-size: 14px;
  }
  .servicesInfoResume span {
    font-size: 14px;
  }
  .servicesImage {
    margin: 30px 0;
    width: 305px;
  }
}

@media (min-width: 1024px) {
  .servicesInfoResume {
    flex-direction: row-reverse;
  }
  .reverse {
    display: flex;
    flex-direction: row !important;
  }
  .textReverse {
    margin-left: 0px !important;
    margin-right: 50px;
  }
  .servicesText {
    margin-left: 50px;
    text-align: left;
  }
  .servicesImage {
    height: 160px;
  }
}

@media (min-width: 1200px) {
  .servicesInfoIntro h1 {
    font-size: 30px;
  }
  .servicesInfoIntro p {
    font-size: 25px;
  }
  .servicesInfoIntro span {
    font-size: 25px;
  }
  .servicesInfoResume h2 {
    font-size: 23px;
  }
  .servicesInfoResume p {
    font-size: 18px;
  }
  .servicesInfoResume span {
    font-size: 18px;
  }
  .servicesImage {
    margin: 30px 0;
    width: 422px;
    height: 180px;
  }
}
