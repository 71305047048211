.landingPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.whiteHousePhone {
  width: 100vw;
}

.whiteHouse {
  width: 100vw;
}

.companyName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.companyName h1 {
  color: white;
  font-family: Raleway-Bold;
  font-size: 23pt;
  margin: 0;
}

.companyName text {
  color: white;
  font-family: OpenSans-Bold;
  font-size: 5pt;
}

@media (min-width: 768px) {
  .companyName h1 {
    font-size: 50px;
  }
  .companyName text {
    font-size: 10px;
  }
}

@media (min-width: 1024px) {
  .companyName h1 {
    font-size: 72px;
  }
  .companyName text {
    font-size: 14px;
  }
}

@media (orientation: portrait) and (min-width: 700px) {
  .whiteHousePhone {
    display: none;
  }
}

@media (orientation: portrait) and (max-width: 699px) {
  .whiteHouse {
    display: none;
  }
}

@media (orientation: landscape) {
  .whiteHousePhone {
    display: none;
  }
}
