.houseLocationWrapper {
  position: relative !important;
  height: 180px !important;
  width: 300px !important;
  margin: 20px auto;
}

@media (min-width: 768px) {
  .houseLocationWrapper {
    width: 400px !important;
  }
}

@media (min-width: 1024px) {
  .houseLocationWrapper {
    width: 500px !important;
    margin: 40px auto 0px !important;
  }
}

@media (min-width: 1400px) {
  .houseLocationWrapper {
    width: 650px !important;
    height: 217px !important;
  }
}
